const list = {
  _1: '北海道',
  _2: '青森県',
  _3: '岩手県',
  _4: '宮城県',
  _5: '秋田県',
  _6: '山形県',
  _7: '福島県',
  _8: '茨城県',
  _9: '栃木県',
  _10: '群馬県',
  _11: '埼玉県',
  _12: '千葉県',
  _13: '東京都',
  _14: '神奈川県',
  _15: '新潟県',
  _16: '富山県',
  _17: '石川県',
  _18: '福井県',
  _19: '山梨県',
  _20: '長野県',
  _21: '岐阜県',
  _22: '静岡県',
  _23: '愛知県',
  _24: '三重県',
  _25: '滋賀県',
  _26: '京都府',
  _27: '大阪府',
  _28: '兵庫県',
  _29: '奈良県',
  _30: '和歌山県',
  _31: '鳥取県',
  _32: '島根県',
  _33: '岡山県',
  _34: '広島県',
  _35: '山口県',
  _36: '徳島県',
  _37: '香川県',
  _38: '愛媛県',
  _39: '高知県',
  _40: '福岡県',
  _41: '佐賀県',
  _42: '長崎県',
  _43: '熊本県',
  _44: '大分県',
  _45: '宮崎県',
  _46: '鹿児島県',
  _47: '沖縄県',
};
export default (code: string) => {
  if (Object.keys(list).includes(code) === false) {
    throw new Error(`prefecturesCodeToString: invalid code: ${code}`);
  }

  // @ts-ignore
  return list[code];
};

export default [
  {
    label: '海岸',
    value: 'location_ocean',
  },
  {
    label: '川沿い',
    value: 'location_river',
  },
  {
    label: '湖畔',
    value: 'location_lake',
  },
  {
    label: '林間',
    value: 'location_forest',
  },
  {
    label: '野原',
    value: 'location_plateau',
  },
  {
    label: '高台',
    value: 'location_hill',
  },
  {
    label: '公園',
    value: 'location_park',
  },
];

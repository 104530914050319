import { useEffect, useState } from 'react';

import { useGetCampSiteByIdQuery } from '@/__generated__/public-graphql';
import { useDeleteCampSiteLikeMutation } from '@/__generated__/user-graphql';
import constLocations from '@/contents/constants/constLocations';
import Card from '@/contents/General/Card';
import type { Campsite, CardSize } from '@/types/domain';
import ImageUrlConvert from '@/utils/ImageUrlConvert';
import prefecturesCodeToString from '@/utils/prefecturesCodeToString';

import useCampSiteLikeFromPublic from '../CampSiteLikeList/public/useCampSiteLikeFromPublic';
import useGetLoginUser from '../User/user/useGetLoginUser';

const CampSiteCard = ({
  campSiteId,
  campSite: inputCampSite,
  cardSize,
  variant,
  hideFavorite,
  disableBottomBorderRadius,
}: {
  campSiteId?: string;
  campSite?: Campsite;
  cardSize: CardSize;
  variant?: 'flex' | 'normal' | 'inner-title';
  hideFavorite?: boolean;
  disableBottomBorderRadius?: boolean;
}) => {
  const { data } = useGetCampSiteByIdQuery(
    {
      id: campSiteId ?? '',
    },
    {
      enabled: !!campSiteId,
    }
  );
  const campSite = data?.campsite_by_pk ?? inputCampSite;
  const [favorite, setFavorite] = useState(false);
  const { user: loginUser } = useGetLoginUser();

  const { data: likesData, refetch } = useCampSiteLikeFromPublic(
    loginUser?.id ?? '',
    {
      enabled: !!loginUser?.id,
    }
  );
  useEffect(() => {
    setFavorite(
      (likesData?.all ?? []).findIndex(
        (item) => item.campsite_id === campSite?.id
      ) !== -1
    );
  }, [likesData]);

  if (!campSite) return <></>;
  const tags = constLocations
    .filter((item) => {
      // @ts-ignore
      if (!campSite[item.value]) {
        return false;
      }
      // @ts-ignore
      return campSite[item.value] === true;
    })
    .map((item) => item.label);
  return (
    <Card
      id={campSite.id}
      disableBottomBorderRadius={disableBottomBorderRadius}
      variant={variant ?? 'normal'}
      cardSize={cardSize}
      imageUrl={
        campSite.image?.file_name
          ? ImageUrlConvert(campSite.image.file_name, true)
          : `/assets/images/common/noimage_${
              cardSize.match(/vt|wide/)?.length !== 0 && variant !== 'flex'
                ? 'wide'
                : 'sm'
            }.png`
      }
      title={campSite.name}
      areaName={prefecturesCodeToString(campSite.prefectures_code)}
      tags={tags}
      link={`/camp/${campSite.id}`}
      {...(() => {
        if (hideFavorite) {
          return {};
        }
        return {
          favorite,
        };
      })()}
      onUnFavorite={(id) => {
        useDeleteCampSiteLikeMutation
          .fetcher({
            campsite_id: id,
            user_id: loginUser?.id ?? '',
          })()
          .then(() => {
            refetch();
          });
      }}
    />
  );
};
export default CampSiteCard;

import { useMediaQuery } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import type { UrlObject } from 'url';

import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';
import type { CardSize } from '@/types/domain';
import { Page } from '@/types/domain';

import { GlobalContext } from '../hooks/state';

declare type Url = string | UrlObject;

interface Props extends React.HTMLProps<BoxProps> {
  id?: string;
  variant: 'normal' | 'inner-title' | 'flex';
  cardSize: CardSize;
  imageUrl: string;
  title?: string;
  favorite?: boolean;
  areaName?: string;
  tags?: string[];
  description?: string;
  link?: Url;
  noElipsis?: boolean;
  noPointer?: boolean;
  disableBottomBorderRadius?: boolean;
  onUnFavorite?: (id: string) => void;
}

const StyledBox = styled(Box)<{
  size: CardSize;
  is_pc: string;
  no_pointer: 'true' | 'false';
}>`
  position: relative;
  overflow: visible;

  :last-child::after {
    position: absolute;
    content: '';
    top: 0;
    left: 100%;
    height: 1px;
    width: 24px;
    opacity: 0;
  }
  ${(props) =>
    props.no_pointer === 'true' ? 'cursor: default;' : 'cursor: pointer;'}
  ${(props) =>
    props.size === 'sm' &&
    props.is_pc &&
    `max-width: 210px;
      width: 40.5vw;
    `}
  ${(props) =>
    props.size === 'sm' &&
    !props.is_pc &&
    `max-width: 180px;
      width: 40.5vw;
      `}
  ${(props) =>
    props.size === 'vt' &&
    props.is_pc &&
    `max-width: 210px;
      width: 40.5vw;
    `}
  ${(props) =>
    props.size === 'vt' &&
    !props.is_pc &&
    `max-width: 180px;
      width: 40.5vw;
    `}
  ${(props) =>
    props.size === 'hr' &&
    `width: 75.5vw;
      max-width: 313px;
    `}
  ${(props) =>
    props.size === 'lg' &&
    `width: 75.5vw;
      max-width: 380px;
    `}
`;

const ImageContainer = styled(Box)<{
  disable_bottom_border_radius?: 'true' | 'false';
  size: CardSize;
  variant: 'normal' | 'inner-title' | 'flex';
  is_pc: string;
}>`
  position: relative;
  padding: ${(props) =>
    (props.variant === 'inner-title' || props.size === 'lg') && 'unset'};
  ${(props) =>
    props.size === 'sm' &&
    props.is_pc &&
    `max-width: 210px;
      max-height: 210px;
      width: 40.5vw;
      height: 40.5vw;
    `}
  ${(props) =>
    props.size === 'sm' &&
    !props.is_pc &&
    `max-width: 180px;
      max-height: 180px;
      width: 40.5vw;
      height: 40.5vw;
      `}
  ${(props) =>
    props.size === 'vt' &&
    props.is_pc &&
    `max-width: 210px;
      width: 40.5vw;
      height: 50vw;
      max-height: 256px;
    `}
  ${(props) =>
    props.size === 'vt' &&
    !props.is_pc &&
    `max-width: 180px;
      width: 40.5vw;
      height: 50vw;
      max-height: 220px;
    `}  
  ${(props) =>
    props.size === 'hr' &&
    `width: 75.5vw;
      height: 42.7vw;
      max-width: 313px;
      max-height: 180px;
    `}
  ${(props) =>
    props.size === 'lg' &&
    `width: 75.5vw;
      height: 75.5vw;
      max-width: 380px;
      max-height: 380px;
    `}
  ${(props) =>
    props.size === 'wide' &&
    `width: 100%;
      height: calc((100vw - 48px) * 0.475);
      max-height: 218px;
    `}
  z-index: 0;
  overflow: hidden;
  ${(props) =>
    props.disable_bottom_border_radius === 'true'
      ? `border-radius: 8px 8px 0 0;
    `
      : `border-radius: 8px;`}
`;

const AreaName = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  width: fit-content;
  height: ${theme.spacing(3)};
  border-radius: 14px;
  background: ${theme.palette.primary.main};
`;

const Tags = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}) => (condition ? <>{wrapper(children)}</> : <>{children}</>);

const LinkWrapper = ({
  link,
  children,
}: {
  link?: Url;
  children: React.ReactNode;
}) => {
  const { page } = useContext(GlobalContext);
  const router = useRouter();

  const reload = () => {
    if (page === Page.CAMPSITE && link) {
      router.push(link).then(() => router.reload());
    }
  };

  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={(c) => {
        return link ? (
          <Link href={link}>
            <a onClick={reload}>{c}</a>
          </Link>
        ) : (
          <></>
        );
      }}
    >
      {children}
    </ConditionalWrapper>
  );
};

const Card = ({
  id,
  variant,
  cardSize,
  favorite,
  imageUrl,
  title,
  areaName,
  tags,
  description,
  link,
  noElipsis,
  onUnFavorite,
  noPointer,
  disableBottomBorderRadius,
}: Props) => {
  const router = useRouter();
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const { user, isLogin } = useGetLoginUser();
  const isPC = useMediaQuery('(min-width:900px)');

  const toLoginPage = (e: any) => {
    router.push('/login');
    e.preventDefault();
    e.stopPropagation();
  };

  const open = (e: any) => {
    if (!favorite) {
      setDialogStatus({
        ...dialogStatus,
        open: true,
        type: 'FAVORITE',
        props: { id },
      });
    } else if (onUnFavorite) {
      if (!id) {
        throw new Error('id not found');
      }
      onUnFavorite(id);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const getImageWidth = () => {
    switch (cardSize) {
      case 'sm':
        return '210px';
      case 'vt':
        return '210px';
      case 'hr':
        return '313px';
      case 'lg':
        return '380px';
      case 'wide':
        return '380px';
      default:
        return '210px';
    }
  };

  return (
    <LinkWrapper link={link}>
      {variant !== 'flex' ? (
        <StyledBox
          size={cardSize}
          no_pointer={noPointer ?? false ? 'true' : 'false'}
          is_pc={isPC ? isPC.toString() : ''}
        >
          <ImageContainer
            size={cardSize}
            variant={variant}
            is_pc={isPC ? isPC.toString() : ''}
            disable_bottom_border_radius={
              disableBottomBorderRadius ? 'true' : 'false'
            }
          >
            <Image
              src={imageUrl}
              alt={title ?? ''}
              objectFit="cover"
              layout="fill"
              loading="lazy"
              sizes={getImageWidth()}
            />
            {favorite !== undefined && (
              <>
                {user && typeof user === 'object' ? (
                  <IconButton
                    onClick={open}
                    sx={{
                      zIndex: 1,
                      marginLeft: 'auto',
                      position: 'absolute',
                      right: 0,
                      marginRight:
                        cardSize === 'sm' ? theme.spacing(1) : theme.spacing(2),
                      marginTop:
                        cardSize === 'wide' ||
                        cardSize === 'lg' ||
                        cardSize === 'hr'
                          ? theme.spacing(2)
                          : theme.spacing(1),
                    }}
                  >
                    <Icon iconType={favorite ? 'favorite' : 'not-favorite'} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={toLoginPage}
                    sx={{
                      zIndex: 1,
                      marginLeft: 'auto',
                      position: 'absolute',
                      right: 0,
                      marginRight:
                        cardSize === 'sm' ? theme.spacing(1) : theme.spacing(2),
                      marginTop:
                        cardSize === 'wide' ||
                        cardSize === 'lg' ||
                        cardSize === 'hr'
                          ? theme.spacing(2)
                          : theme.spacing(1),
                    }}
                  >
                    <Icon iconType={'not-favorite'} />
                  </IconButton>
                )}
              </>
            )}

            <Stack
              column
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                padding:
                  variant === 'inner-title' ||
                  cardSize === 'lg' ||
                  cardSize === 'hr'
                    ? theme.spacing(2)
                    : theme.spacing(1),
                background:
                  variant === 'inner-title' || cardSize === 'lg'
                    ? 'linear-gradient(rgba(0, 0, 0, 0) 45%, rgb(0 0 0 / 45%) 100%)'
                    : '',
              }}
            >
              <Box sx={{ marginTop: 'auto', zIndex: 1 }}>
                <Stack space={1}>
                  {areaName && (
                    <AreaName>
                      <Typography
                        variant="body2"
                        color="white"
                        fontSize={10}
                        noWrap
                      >
                        {areaName}
                      </Typography>
                    </AreaName>
                  )}
                  {(variant === 'inner-title' || cardSize === 'lg') && (
                    <Typography
                      variant="body2"
                      color="white"
                      fontSize={16}
                      lineHeight={1.3}
                    >
                      {title}
                    </Typography>
                  )}
                </Stack>
                {cardSize === 'lg' && description && (
                  <Typography
                    fontSize={12}
                    color="white"
                    sx={{ paddingTop: theme.spacing(1) }}
                    ellipsis={1}
                  >
                    {description}
                  </Typography>
                )}
              </Box>
            </Stack>
          </ImageContainer>
          {variant === 'normal' &&
            (cardSize === 'sm' || cardSize === 'hr' || cardSize === 'wide') && (
              <Box sx={{ paddingTop: theme.spacing(2) }}>
                <Typography
                  fontSize={14}
                  variant="body2"
                  {...(!noElipsis && { ellipsis: 1 })}
                >
                  {title}
                </Typography>
                {tags && tags.length > 0 && (
                  <Tags>
                    {tags.map((tag, index) => (
                      <Typography
                        key={`${tag + index}`}
                        fontSize={12}
                        sx={{ display: 'inline' }}
                      >
                        {tag}
                        {tags.length !== index + 1 && <span>/</span>}
                      </Typography>
                    ))}
                  </Tags>
                )}
                {description && (
                  <Typography
                    fontSize={12}
                    ellipsis={1}
                    sx={{ paddingTop: theme.spacing(0.5) }}
                  >
                    {description}
                  </Typography>
                )}
              </Box>
            )}
        </StyledBox>
      ) : (
        <Stack alignCenter>
          <Stack space={3}>
            <Box
              sx={{
                position: 'relative',
                width: '112px',
                height: '112px',
                minWidth: '112px',
                minHeight: '112px',
                borderRadius: 2,
                overflow: 'hidden',
                '& img': {
                  objectFit: 'cover',
                },
              }}
            >
              <Image
                src={imageUrl}
                width={112}
                height={112}
                alt={title ?? ''}
                loading="lazy"
              />
            </Box>
            <Stack column space={1}>
              {areaName && (
                <AreaName>
                  <Typography fontSize={12} fontWeight={500} color="white">
                    {areaName}
                  </Typography>
                </AreaName>
              )}
              <Typography fontSize={16} fontWeight={500} ellipsis={2}>
                {title}
              </Typography>
              {tags && tags.length > 0 && (
                <Tags>
                  {tags.map((tag, index) => (
                    <Typography
                      key={`${tag + index}`}
                      fontSize={12}
                      sx={{ display: 'inline' }}
                    >
                      {tag}
                      {tags.length !== index + 1 && <span>/</span>}
                    </Typography>
                  ))}
                </Tags>
              )}
              {description && (
                <Typography fontSize={12} ellipsis={1}>
                  {description}
                </Typography>
              )}
            </Stack>
          </Stack>
          {favorite !== undefined && (
            <Box marginLeft="auto" paddingLeft={1}>
              {user && typeof user === 'object' ? (
                <IconButton onClick={open}>
                  <Icon iconType={favorite ? 'favorite' : 'not-favorite'} />
                </IconButton>
              ) : (
                <IconButton onClick={toLoginPage}>
                  <Icon iconType={'not-favorite'} />
                </IconButton>
              )}
            </Box>
          )}
        </Stack>
      )}
    </LinkWrapper>
  );
};

export default Card;
